.text {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: $black;
  font-size: 21px;

  @include media('<=1024px') {
    font-size: 18px;
  }

  p {
    margin: 0 0 20px 0;

    @include media('<=1024px') {
      margin: 0 0 15px 0;
    }
  }

  li {
    list-style: none;
    position: relative;
    padding-left: 15px;
    margin-left: 15px;

    &::before {
      content: '-';
      position: absolute;
      left: 0;
    }
  }
}
