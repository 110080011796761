.center-load {
  width: 100%;
  text-align: center;
  color: $orange;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
}

.button {
  display: inline-block;
  background: $orange;
  color: $white;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 14px 50px 12px;
  text-transform: uppercase;
  border: 2px solid $orange;
  box-sizing: border-box;
  transition: 0.5s;
  border-radius: 12px;

  @include media('<=1024px') {
    padding: 14px 30px 12px;
  }

  @include media('<=768px') {
    padding: 10px 20px 8px;
    font-size: 12px;
    font-weight: normal;
  }

  &--full {
    width: 100%;
    text-align: center;
  }


  &--disabled,
  &:disabled {
    opacity: 0.3;
  }


  &:hover {
    background: transparent;
    color: $orange;
  }

  &:focus {
    outline: 0;
  }


}

.transparent-button {
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  color: $orange;
  display: inline-block;
  transition: 0.5s;

  &__icon {
    width: 30px;
    height: 30px;
    line-height: 26px;
    border-radius: 10px;
    background: $orange;
    display: block;
    color: $white;
    margin: 0 auto 30px auto;
    transition: 0.5s;

    @include media('<=1024px') {
      margin: 0 auto 10px auto;
    }

    svg {
      width: 12px;
      height: 12px;
      vertical-align: middle;
    }

    &--bottom {
      margin: 30px auto 0 auto;

      @include media('<=1024px') {
        margin: 10px auto 0 auto;
      }
    }
  }

  &:hover {
    color: $dark;

    .transparent-button__icon {
      background: $dark;
    }
  }
}
