.section {
  border-radius: 6px;
  background: $white;
  padding: 90px 100px;
  margin-bottom: 20px;
  width: 100%;
  display: inline-block;

  @include media('<=1024px') {
    padding: 45px 50px;
  }

  @include media('<=768px') {
    padding: 5%;
  }

  &--small {
    padding: 80px 70px;
    vertical-align: top;

    @include media('<=1024px') {
      padding: 40px 35px;
    }

    @include media('<=768px') {
      padding: 5%;
    }
  }

  &--full {
    padding: 0;
    overflow: hidden;
  }

  &--center {
    text-align: center;

    img {
      vertical-align: middle;
      max-width: 100%;
    }
  }

  &--boxed {
    padding: 40px 200px 70px 200px;

    @include media('<=1024px') {
      padding: 20px 100px 35px 100px;
    }

    @include media('<=768px') {
      padding: 5% 7.5%;
    }
  }

  &--transparent {
    background: transparent;
    padding: 150px 0;

    @include media('<=1024px') {
      padding: 75px 0;
    }

    @include media('<=768px') {
      padding: 5%;
    }
  }

  &__set-half {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @include media('<=1024px') {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
      display: block;
    }
  }
}

.text-section {
  &__title {
    margin-bottom: 40px;

    @include media('<=1024px') {
      margin-bottom: 20px;
    }
  }

  &__lead {
    padding: 0 90px;

    @include media('<=1024px') {
      padding: 0 45px;
    }

    @include media('<=768px') {
      padding: 0 3%;
    }
  }
}

.news-section {
  &__title {
    margin-bottom: 90px;

    @include media('<=1024px') {
      margin-bottom: 45px;
    }

    @include media('<=768px') {
      margin-bottom: 5%;
    }
  }

  &__articles {
    margin-bottom: 70px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;

    @include media('<=1024px') {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 0;
      display: block;
    }
  }

  &__button {
    text-align: center;
  }
}

.double-section {
  &__title {
    margin-bottom: 70px;

    @include media('<=1024px') {
      margin-bottom: 35px;
    }

    @include media('<=768px') {
      margin-bottom: 3.5%;
    }
  }

  &__logo {
    color: $dark;
    vertical-align: middle;
    text-align: center;
    max-width: 300px;
    margin: 0 auto 70px;

    svg {
      max-width: 100%;
    }

    @include media('<=1024px') {
      margin: 0 auto 35px;
      max-width: 200px;
    }

    @include media('<=768px') {
      max-width: 100%;
    }

  }

  &__slider {
    margin-bottom: 140px;
    overflow: hidden;

    @include media('<=1024px') {
      margin-bottom: 70px;
    }

    .flickity-page-dots {
      bottom: -70px;

      @include media('<=1024px') {
        bottom: -35px;
      }

      .dot {
        background: transparent;
        width: 15px;
        height: 15px;
        border: 2px solid $grey;
        opacity: 1;

        &.is-selected {
          border-color: $orange;
          background: $orange;
        }
      }
    }
  }

  &__slide {
    width: 100%;
    text-align: center;
    height: 180px;

    @include media('<=1024px') {
      height: 90px;
    }

    img {
      vertical-align: middle;
      max-width: 300px;
      max-height: 180px;
      top: 50%;
      position: relative;
      transform: translateY(-50%);

      @include media('<=1024px') {
        max-width: 200px;
        max-height: 90px;
      }
    }
  }

  &__button {
    text-align: center;
  }
}

#pou-ck {
  path {
    &:last-of-type {
      fill: $yellow;
    }
  }

  .bdark,
  .cdark {
    path {
      fill: $dark !important;
    }
  }
}

.copyright {
  padding: 50px 0;
  border-top: 1px solid #ccc0bc;
  text-align: center;

  @include media('<=1024px') {
    padding: 25px 0;
  }

  &__text {
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a18c86;
  }
}

.intro-section {
  display: flex;
  justify-content: space-between;

  @include media('<=1024px') {
    display: block;
  }

  &__left {
    width: 40%;
    position: relative;
    z-index: 10;

    @include media('<=1024px') {
      width: 100%;
    }
  }

  &__logo {
    background: $orange;
    height: 70%;
    text-align: center;

    @include media('<=1024px') {
      height: auto;
      padding: 20px 0;
    }

    img {
      vertical-align: middle;
      max-width: 210px;
      top: 50%;
      transform: translateY(-50%);
      position: relative;

      @include media('<=1024px') {
        top: initial;
        transform: translate(0);
      }
    }
  }

  &__find-out {
    height: 30%;
    vertical-align: middle;
    background: $white;
    text-align: center;

    @include media('<=1024px') {
      height: auto;
      padding: 20px 0;
    }

    .move-to-center {
      top: 50%;
      transform: translateY(-50%);
      position: relative;

      @include media('<=1024px') {
        top: initial;
        transform: translate(0);
      }
    }
  }

  &__slider {
    width: 62%;
    margin-left: -1%;
    margin-right: -1%;
    overflow: hidden;

    @include media('<=1024px') {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }


    .flickity-page-dots {
      bottom: 30px;
      text-align: right;
      padding-right: 40px;

      @include media('<=1024px') {
        bottom: 15px;
        text-align: center;
        padding-right: 0;
      }

      .dot {
        background: transparent;
        width: 15px;
        height: 15px;
        border: 2px solid $grey;
        opacity: 1;

        &.is-selected {
          border-color: $orange;
          background: $orange;
        }
      }
    }
  }


  .flickity-slider {
    transform: none !important;
  }

  &__slide {
    height: 800px;
    width: 100%;
    left: 0 !important;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: -1;

    @include media('<=1024px') {
      height: 480px;
    }

    .post-image {
      @include media('<=1024px') {
        bottom: 0;
        top: auto;
        transform: translate(-50%, 0);
        height: 100%;
      }
    }

    &.is-selected {
      opacity: 1;
      z-index: 0
    }
  }
}

.post-section {
  &__image {
    .post-image {
      top: 0;
      transform: translate(-50%, 0);
    }
  }

  &__content {
    padding: 50px 200px 90px 200px;

    @include media('<=1024px') {
      padding: 3% 5% 5%;
    }
  }

  &__text {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: $black;
    font-size: 18px;

    h1 {
      font-size: 48px;
      margin: 0 0 20px 0;

      @include media('<=1024px') {
        font-size: 36px;
        margin: 0 0 15px 0;
      }
    }

    h2 {
      font-size: 32px;
      margin: 0 0 20px 0;

      @include media('<=1024px') {
        font-size: 28px;
        margin: 0 0 15px 0;
      }
    }

    h3 {
      font-size: 24px;
      margin: 0 0 20px 0;

      @include media('<=1024px') {
        font-size: 22px;
        margin: 0 0 15px 0;
      }
    }

    h4 {
      font-size: 19px;
      margin: 0 0 10px 0;
    }

    h5 {
      font-size: 15px;
      margin: 0 0 10px 0;
    }

    h6 {
      font-size: 12px;
      margin: 0 0 10px 0;
    }

    p {
      margin: 0 0 20px 0;
    }

    a {
      color: $orange;
      transition: 300ms;

      &:hover {
        color: $dark;
      }
    }

    li {
      list-style: none;
      position: relative;
      padding-left: 15px;
      margin-left: 15px;

      &::before {
        content: '-';
        position: absolute;
        left: 0;
      }
    }
  }
}
