.headline {
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: $black;
  margin: 0;
  transition: 0.5s;

  &--small {
    font-size: 16px;

    @include media('<=1024px') {
      font-size: 14px;
    }

    @include media('<=768px') {
      font-size: 12px;
    }
  }

  &--medium {
    font-size: 21px;

    @include media('<=1024px') {
      font-size: 18px;
    }

    @include media('<=768px') {
      font-size: 16px;
    }
  }

  &--color-orange {
    color: $orange;
  }

  &--margin-bottom {
    margin-bottom: 40px;

    @include media('<=1024px') {
      margin-bottom: 20px;
    }
  }

  @include media('<=1024px') {
    font-size: 26px;
  }

  @include media('<=768px') {
    font-size: 20px;
  }
}
