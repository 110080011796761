.site-header {
  padding-top: 80px;
  margin-bottom: 70px;

  @include media('<=1024px') {
    padding-top: 0px;
    margin-bottom: 30px;
  }
}

.site-header-set-flex {
  display: flex;
  justify-content: space-between;

  @include media('<=1024px') {
    display: block;
  }
}

.logo {
  color: $dark;
  width: 220px;

  @include media('<=1024px') {
    width: 100%;
    display: block;
    text-align: center;

    svg {
      max-width: 120px;
    }
  }

  &--finis {
    color: $blue;

    svg {
      max-height: 80px;
    }
  }
}
