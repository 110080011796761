.image {
    width: 100%;
    position: relative;
    overflow: hidden;

    &--1-1 {
        padding-bottom: 100%;
    }

    &--1-2 {
        padding-bottom: 130%;
    }

    &--2-1 {
        padding-bottom: 50%;
    }

    &--16-9 {
        padding-bottom: 56.25%;
    }

    &--4-3 {
        padding-bottom: 75%;
    }
}

.post-image {
    position: absolute;
    width: 100%;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: 0.5s;
}

img {
    max-width: 100%;
    height: auto;
}
