.lead {
  font-size: 32px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: $black;

  a {
    color: $orange;
    transition: 0.5s;

    &:hover {
      color: $black;
    }
  }

  &--small {
    font-size: 21px;

    @include media('<=1024px') {
      font-size: 18px;
    }

    @include media('<=768px') {
      font-size: 16px;
    }
  }

  &--margin-bottom {
    margin-bottom: 20px;

    @include media('<=1024px') {
      margin-bottom: 15px;
    }
  }

  @include media('<=1024px') {
    margin-bottom: 24px;
  }

  @include media('<=768px') {
    font-size: 18px;
  }
}
