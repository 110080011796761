.title {
  font-size: 48px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $orange;
  margin: 0;

  @include media('<=1024px') {
    font-size: 38px;
  }

  @include media('<=768px') {
    font-size: 28px;
  }

  &--small {
    font-size: 34px;

    @include media('<=1024px') {
      font-size: 28px;
    }

    @include media('<=768px') {
      font-size: 18px;
    }
  }

  &--margin-bottom {
    margin-bottom: 110px;

    @include media('<=1024px') {
      margin-bottom: 70px;
    }
  }
}
