.post {
  &__image {
    margin-bottom: 20px;

    @include media('<=1024px') {
      margin-bottom: 15px;
    }
  }

  &__date {
    margin-bottom: 10px;
  }

  @include media('<=1024px') {
    margin-bottom: 40px;
  }

  &:hover {
    .headline {
      color: $orange;
    }

    .post-image {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}
