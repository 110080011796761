.main-nav {
  width: calc(100% - 220px);
  text-align: right;

  @include media('<=1024px') {
    width: 100%;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: inline-block;

    &.current_page_parent,
    &.current_page_item {
      a {
        color: $orange;
      }
    }
  }

  a {
    font-size: 19px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 2px;
    color: $dark;
    text-transform: uppercase;
    transition: 0.5s;
    padding: 0 10px;

    &:hover {
      color: $orange;
    }

    @include media('<=1024px') {
      font-size: 14px;
    }
  }
}
