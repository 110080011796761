@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,800,900&display=swap&subset=latin-ext');

.svgIcons {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $easy;
  font-family: 'Montserrat',
    sans-serif;
  font-weight: normal;

  @include media('<=tablet') {
    width: 100%;
    height: 100%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
span {
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  margin: 0;
  padding: 0;
}

body,
html,
ul,
ol {
  padding: 0;
  margin: 0;
}

button {
  padding: 0;
  outline: 0;
}

main.site {
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.center {
  max-width: 1160px;
  margin: 0 auto;

  @include media('<=1420px') {
    padding: 0 50px;
  }

  @include media('<=980px') {
    padding: 0 20px;
  }
}

// Alpha color
@function alpha($color: $black, $opacity: 0.5) {
  $alpha-color-value: rgba($color, $opacity);
  @return $alpha-color-value;
}

input,
textarea,
button {
  font-family: $default;
}

svg,
g,
path {
  fill: currentColor;
}

// wordpress default aligment

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
